import React, {useContext} from "react"
import styled from 'styled-components'
import { FirebaseContext} from '@components/Firebase';
import { ListViewItem, AppPageHeader } from '@common';
import { graphql } from "gatsby"
import { useTranslation } from 'react-i18next'

import Header from "@menus/header"

const FilterWrapper = styled.div`
  padding: 5% 5%;
  background-color: ${props => props.theme.backgroundColor};
`

const getStoriesOrder = (type, stories) => {
  switch (type) {
    case "new":
       return stories
        .slice()
        .sort((storyA, storyB) => {
            const dateA = new Date(storyA.node.dateCreated);
            const dateB= new Date(storyB.node.dateCreated);
            return dateB - dateA
          })

    case "male":
        return stories.filter(story => story.node.voices[0].gender === 'male')

    case "female":
        return stories.filter(story => story.node.voices[0].gender === 'female')
  }






}


const ShowMore = (props) => {
  const { t } = useTranslation()
  const { user } = useContext(FirebaseContext)
  const filter = props.location.state?.storyFilter 
 

  return (
    <>
    <Header location={props.location} /> 
    <AppPageHeader pageTitle={t('filter-results.sectionTitle-1')}/>
    {user && 
      <FilterWrapper>
        {getStoriesOrder(filter,props.data.allCommunityStory.edges).map(edge =>(
          <ListViewItem
            id={edge.node.id}
            slug={edge.node.slug}
            title={edge.node.title}
            sampleAudioRef={edge.node.sampleAudioRef}
            storyCover={edge.node.localImage.coverImage.gatsbyImageData}
            thumbnail={edge.node.localImage.thumbnail.gatsbyImageData}
            duration={edge.node.duration}
            voices={edge.node.voices}
            mainFantasy={edge.node.mainFantasy.en.fantasy}
            spiciness={edge.node.spiciness}
            premiumContent={edge.node.premiumContent}
            audiodesiresOriginal={edge.node.audiodesiresOriginal}
            key={edge.node.id}
          />
        ))}
      </FilterWrapper>
    }
    </>
  )
}

export default  ShowMore ; 
export const query = graphql`
  query CommunityStoriesDings{
    allCommunityStory{
      edges {
        node {
          ...sliderItemCommunityInfo
        }
      }
    }
  }
`;  

